import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Import the AuthService type from the SDK
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit {

  profileJson: string;
  selectedRole: any;
  selectedEnv: any;
  role_msg;
  token_status;
  isDisplay: boolean = true;
  isCopyText: boolean = false;
  isWorkingEnv: boolean = false;
  isLogin: boolean = false;
  tokenRole;
  token_info;
  acc_token_info;
  env_msg;
  realm;
  key_realm;
  key_token;
  key_lb4b_token;

  constructor(public auth: AuthService) { }

  ngOnInit(): void {

    this.auth.user$.subscribe(
      (profile) => {
        this.profileJson = JSON.stringify(profile)
      }
    );

    this.auth.idTokenClaims$.subscribe((claims) => {
      if (claims) {
        var kcToken = claims["http://localhost:4200/tokenFromSails"];
        var tokenRole = claims["http://localhost:4200/selectedRole"];
        var tokenEnv = claims["http://localhost:4200/selectedEnv"];
        if (tokenRole == "undefined") {
          this.isDisplay = false;
          this.isWorkingEnv = false;
          this.token_status = "selected role is undefined, pls logout and login again with role selected";
        } else {
          this.isDisplay = false;
          this.isWorkingEnv = false;
          this.token_status = "kc token info available now for selected " + tokenRole + " role and " + tokenEnv + " env.";
          this.isCopyText = true;
          this.acc_token_info = "Bearer " + kcToken.access_token;
          this.key_token = "authorization";
          this.key_realm = "realmname";
          this.key_lb4b_token = "lb4b-token";
          var kct_realm = atob((kcToken.access_token).split('.')[1])
          var realm_in = JSON.parse(kct_realm)["iss"].split("/");
          var realm_i = realm_in[realm_in.length - 1];
          this.realm = realm_in[realm_in.length - 1];
          this.token_info = JSON.stringify(kcToken);
          document.cookie = "authorization=Bearer " + kcToken.access_token+";path=/;domain=.liveb4buy.com;";
          document.cookie = "realmname="+realm_in[realm_in.length - 1]+";path=/;domain=.liveb4buy.com;";
          document.cookie = "authorization=Bearer " + kcToken.access_token+";path=/;domain=.livearf.com;";
          document.cookie = "realmname="+realm_in[realm_in.length - 1]+";path=/;domain=.livearf.com;";
        }
      }
    });

    this.auth.error$.subscribe((error) => {
      console.log(error)
      //  this.auth.logout({ returnTo: window.location.origin });
    });
  }

  // getting roles
  rolesList: any = ['lb4b-employee', 'lb4b-admin', 'lb4b-seller', 'lb4b-buyer']

  form1 = new FormGroup({
    roles: new FormControl('', Validators.required)
  });

  get f1() {
    return this.form1.controls;
  }

  submit1() {
    this.selectedRole = this.form1.value;
    this.isDisplay = false;
    this.isWorkingEnv = true;
    // let default_role = 'previously selected role'
    this.role_msg = "selected role is " + "'" + this.selectedRole.roles + "'";

  }

  // getting envs
  workingEnvList: any = ['dev', 'prod']

  form2 = new FormGroup({
    workingEnv: new FormControl('', Validators.required)
  });

  get f2() {
    return this.form2.controls;
  }

  submit2() {
    this.selectedEnv = this.form2.value;
    this.isDisplay = false;
    this.isWorkingEnv = false;
    // let default_role = 'previously selected role'
    this.env_msg = "selected env is " + "'" + this.selectedEnv.workingEnv + "'" + ", please click login";
    this.isLogin = true;

  }

  // auth0 login
  loginWithRedirect(): void {
    console.log("in auth0 login");
    this.auth.loginWithRedirect({ role: this.selectedRole.roles, env: this.selectedEnv.workingEnv });

  }

  // auth0 logout
  logout(): void {
    console.log("logged out!!!")
    this.auth.logout({ returnTo: window.location.origin });
  }

}
