<div *ngIf="isDisplay">
    <div class="container">
        <p>Select Required role from dropdown</p>
        <form [formGroup]="form1" (ngSubmit)="submit1()">

            <div class="form-group">
                <label for="roles">Roles:</label>
                <select formControlName="roles" class="form-control">
                    <option>Select Roles</option>
                    <option *ngFor="let role of rolesList">{{role}}</option>
                </select>
                <div *ngIf="f1.roles.touched && f1.roles.invalid" class="alert alert-danger">
                    <div *ngIf="f1.roles.errors.required">Name is required.</div>
                </div>
            </div>

            <button class="btn btn-primary" type="submit1" [disabled]="!form1.valid">Submit</button>
        </form>
    </div>
</div>
<pre>
    {{role_msg}}
</pre>
<div *ngIf="isWorkingEnv">
    <div class="container">
        <p>Select Required workingEnv from dropdown</p>
        <form [formGroup]="form2" (ngSubmit)="submit2()">

            <div class="form-group">
                <label for="workingEnv">WorkingEnv:</label>
                <select formControlName="workingEnv" class="form-control">
                    <option>Select WorkingEnv</option>
                    <option *ngFor="let workingEnv of workingEnvList">{{workingEnv}}</option>
                </select>
                <div *ngIf="f2.workingEnv.touched && f2.workingEnv.invalid" class="alert alert-danger">
                    <div *ngIf="f2.workingEnv.errors.required">WorkingEnv is required.</div>
                </div>
            </div>

            <button class="btn btn-primary" type="submit2" [disabled]="!form2.valid">Submit</button>
        </form>
    </div>
</div>
<pre>
    {{env_msg}}
  </pre>
<div *ngIf="isLogin">
    <button *ngIf="(auth.isAuthenticated$ | async) === false" (click)="loginWithRedirect()">
        Log in
    </button>
</div>
<ul *ngIf="auth.user$ | async as user">
    <li>{{ user.email }}</li>
</ul>
<pre>
    {{token_status}} 
  </pre>

<div *ngIf="isCopyText">
    <h2>used for postman API calls</h2>
    <h3>Get KC Access Token Here!! </h3>
    <p>key:</p>
    <input type="text" [(ngModel)]="key_token" readonly>
    <button cdkCopyToClipboard="{{key_token}}">Copy</button>
    <p>value:</p>
    <input type="text" [(ngModel)]="acc_token_info" readonly>
    <button cdkCopyToClipboard="{{acc_token_info}}">Copy</button>
</div>
<br>
<div *ngIf="isCopyText">
    <h3>Get KC Realm Here!! </h3>
    <p>key:</p>
    <input type="text" [(ngModel)]="key_realm" readonly>
    <button cdkCopyToClipboard="{{key_realm}}">Copy</button>
    <p>value:</p>
    <input type="text" [(ngModel)]="realm" readonly>
    <button cdkCopyToClipboard="{{realm}}">Copy</button>
</div>
<br>
<br>
<br>
<div *ngIf="isCopyText">
    <h2>Only for larf-admin related works</h2>
    <h3>Get KC Token Here!! </h3>
    <p>key:</p>
    <input type="text" [(ngModel)]="key_lb4b_token" readonly>
    <button cdkCopyToClipboard="{{key_lb4b_token}}">Copy</button>
    <p>value:</p>
    <input type="text" [(ngModel)]="token_info" readonly>
    <button cdkCopyToClipboard="{{token_info}}">Copy</button>
</div>
<br>
<button class="btn btn-danger ml-5" (click)="logout()" *ngIf="auth.isAuthenticated$ | async">
    Logout
</button>